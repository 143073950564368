import { useMutation } from 'react-query';

import { Order, OrderProduct, OrderType, OrderProductType } from '@/api/types';
import { useAccount } from '@/hooks';
import { axios } from '@/lib/axios';
import { sortOrderByChargedOrScheduled } from '@/utils';

export type PutProductPayload = {
  accountId: string;
  orderId: Order['id'];
  products: ApiOrderProduct[];
  discountCodes?: string[];
  orderType?: OrderType;
};

type ApiOrderProduct = {
  pet_plan?: string;
  product_code: string;
  quantity: number;
  type: OrderProductType;
};

export const orderProductToApiPayload = (product: OrderProduct) => {
  return {
    pet_plan: product?.pet_plan?.id,
    product_code: product?.code,
    quantity: product?.quantity,
    type: product?.type,
  };
};

const putOrderProducts = ({
  accountId,
  products,
  discountCodes,
  orderType,
  orderId,
}: PutProductPayload) => {
  const currentPathName = window.location.pathname;
  return axios
    .put(`orders/${orderId}`, {
      account: accountId,
      order_products: products,
      discount_codes: discountCodes,
      order_type: orderType,
      order_id: orderId,
      page_path: currentPathName,
    })
    .then((response) => response.data);
};

export const useUpdateOrderProducts = () => {
  const account = useAccount();
  const nextPreviewOrder = account?.orders?.upcoming?.sort(sortOrderByChargedOrScheduled)[0];

  return useMutation(
    ({ productCode, mode }: { productCode: string; mode: 'increase' | 'decrease' }) => {
      const prevProducts = nextPreviewOrder.products;
      let petPlanId: string | undefined;
      let newQuantity = 0;

      const alreadyAddedProduct = prevProducts.find((product) => product.code === productCode);

      if (alreadyAddedProduct) {
        petPlanId = alreadyAddedProduct.pet_plan?.id;
        newQuantity =
          mode === 'increase'
            ? alreadyAddedProduct?.quantity + 1
            : alreadyAddedProduct?.quantity - 1;
      } else {
        petPlanId = prevProducts[0].pet_plan?.id;
        newQuantity = 1;
      }

      const productsPayload = prevProducts
        .filter((product) => product.code !== productCode)
        .map((product) => orderProductToApiPayload(product));
      if (newQuantity > 0) {
        productsPayload.push({
          pet_plan: petPlanId,
          product_code: productCode,
          quantity: newQuantity,
          type: OrderProductType.ONETIME,
        });
      }

      return putOrderProducts({
        accountId: account?.id,
        orderId: nextPreviewOrder?.id,
        orderType: nextPreviewOrder?.order_type,
        products: productsPayload,
      });
    }
  );
};
