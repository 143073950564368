import { getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { IStackProps, HStack, Text } from 'native-base';
import { useEffect, useState } from 'react';

import { loggedOutNavigation, loggedInNavigation, loggedInNavigationRoutes } from '../constants';
import { NavItem } from './NavItem';

interface INavigationProps extends IStackProps {
  isLoggedIn: boolean;
}

export const Navigation = ({ isLoggedIn = false, ...props }: INavigationProps) => {
  const navItems = isLoggedIn ? loggedInNavigation : loggedOutNavigation;
  const route = useRoute();
  const currentActiveRoute = getFocusedRouteNameFromRoute(route);
  const [currentNavItem, setCurrentNavItem] = useState<loggedInNavigationRoutes | null>('home');

  useEffect(() => {
    if (!currentActiveRoute) return;

    if (navItems.some((navItem) => navItem.toLowerCase() === currentActiveRoute?.toLowerCase())) {
      setCurrentNavItem(currentActiveRoute?.toLowerCase() as loggedInNavigationRoutes);
    } else {
      setCurrentNavItem(null);
    }
  }, [currentActiveRoute, navItems]);

  const ShopPillText = () => {
    return (
      <Text size="tinyToCaption" fontFamily="primary" fontWeight="medium">
        New
      </Text>
    );
  };

  return (
    <HStack
      w="100%"
      justifyContent={{ base: 'center', lg: 'flex-start' }}
      alignSelf="center"
      space={{ base: 6, lg: 0 }}
      pt={{ base: 4, lg: 0 }}
      pb={4}
      px={{ base: 4, lg: 0 }}
      flex={1}
      {...props}
    >
      {navItems.map((item, key) => (
        <NavItem
          key={key}
          isCurrentRoute={item.toLowerCase() === currentNavItem}
          navScreen={item}
          type={isLoggedIn ? 'internal' : 'external'}
          stackProps={{ mr: { base: 0, lg: key < navItems.length - 1 ? 6 : 0 } }}
          pillText={item === 'Shop' ? <ShopPillText /> : undefined}
        >
          {item.toLocaleUpperCase()}
        </NavItem>
      ))}
    </HStack>
  );
};
