import { Stack, Heading } from 'native-base';

import { FoodSection } from './FoodSection';

import { Order, Pet } from '@/api/types';
import { EditableCardProps } from '@/components/Elements';
import { addonSeqNoCompare } from '@/utils';
import { getProductCategoriesByOrder } from '@/utils/orders';

interface OrderSummaryProps extends EditableCardProps {
  order: Order;
  pets?: Pet[];
}

export const ProductsSummarySection = ({ order, pets = [] }: OrderSummaryProps) => {
  const productsCategories = getProductCategoriesByOrder(order, pets);

  return (
    <>
      {productsCategories.map((planData) => {
        const { snacks, meals, extras, supplements, pet } = planData;
        const sortedSnacks = snacks.sort(addonSeqNoCompare);
        const sortedSupplements = supplements.sort(addonSeqNoCompare);

        return (
          <Stack key={planData.pet.id}>
            <Heading size="bodyMdToLg" fontWeight="bold" paddingBottom="16px" textAlign="center">
              {pet?.name}
            </Heading>
            <FoodSection products={meals} type="Meals" />
            <FoodSection products={sortedSnacks} type="Snacks" />
            <FoodSection products={sortedSupplements} type="Supplements" />
            <FoodSection products={extras} type="Extras" />
          </Stack>
        );
      })}
    </>
  );
};
