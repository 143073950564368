import { Stack, Heading, Text, Divider, IStackProps, HStack, Badge } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { Image } from '@/components/Elements';
import { getRecipesProductImageSrc, round } from '@/utils';

export interface LineItemPlanCardSnackOrSupplementProps extends IStackProps {
  product: OrderProduct;
  disabled?: boolean;
  showDivider?: boolean;
}

export const LineItemPlanCardSnackOrSupplement = ({
  product,
  disabled,
  showDivider,
  ...props
}: LineItemPlanCardSnackOrSupplementProps) => {
  const itemName = product.product_type === ProductType.SNACK ? 'bag' : 'pack';
  return (
    <HStack space={{ base: 2, lg: 4 }} {...props}>
      <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
        <Image
          source={{ uri: getRecipesProductImageSrc(product.recipes, false) }}
          w="100%"
          h="100%"
          alt={product.recipes[0].name}
          resizeMode="contain"
          opacity={disabled ? 50 : undefined}
        />
      </Stack>
      <Stack flex={3} space={2}>
        <HStack alignItems="center" justifyContent="space-between">
          <Heading
            size="bodyMdToLg"
            fontWeight="bold"
            color={disabled ? 'sntGrey.primary' : undefined}
          >
            {product.recipes[0].name}
          </Heading>
          <Badge
            variant="smallInfoPillSecondary"
            _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
            borderColor={disabled ? 'sntGrey.primary' : undefined}
          >
            {product.unit_price !== undefined
              ? `$${round(product.quantity * Number(product.unit_price), 2)}`
              : '$ --'}
          </Badge>
        </HStack>
        <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
          {product.quantity} {itemName}
          {product.quantity !== 1 && 's'}
        </Text>
        {showDivider ? <Divider bgColor="gallery" w="85%" mt={4} /> : null}
      </Stack>
    </HStack>
  );
};
