import { HStack, Stack, Text } from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { GestureResponderEvent } from 'react-native';

import { INACTIVE_STATUSES } from '@/api';
import { PetPillButton, PopUpProps, PortalPopUp } from '@/components/Elements';
import { useAccount } from '@/hooks';
import {
  dateFormat,
  dateRangeFormat,
  getEstimatedArrivalRange,
  sortPetsAlphabetically,
} from '@/utils';

type SelectDogProps = {
  initialPetPlanIds: string[];
  chargeDate: Date;
  onConfirm: (petIds: string[]) => void;
  showDateEstimateFooter?: boolean;
  isConfirmButtonLoading?: boolean;
} & Omit<PopUpProps, 'onConfirm'>;

export const DogRescheduleModal = ({
  initialPetPlanIds,
  chargeDate,
  onConfirm,
  showDateEstimateFooter = true,
  isConfirmButtonLoading = false,
  ...props
}: SelectDogProps) => {
  const account = useAccount();
  const [selectedPetIds, setSelectedPetIds] = useState<string[]>([]);

  const { deliverStartDay, deliverEndDay } = getEstimatedArrivalRange(chargeDate);

  useEffect(() => {
    if (initialPetPlanIds) {
      setSelectedPetIds(
        account.pets
          .filter((pet) => initialPetPlanIds.includes(pet.pet_plan.id))
          .map((pet) => pet.id)
      );
    }
  }, [account.pets, initialPetPlanIds]);

  const sortedActivePets = useMemo(
    () =>
      sortPetsAlphabetically(
        account?.pets.filter((pet) => !INACTIVE_STATUSES.includes(pet.status))
      ),
    [account?.pets]
  );

  const togglePet = (e: GestureResponderEvent, petId: string) => {
    e.preventDefault();
    if (selectedPetIds.includes(petId)) {
      setSelectedPetIds(selectedPetIds.filter((id) => petId !== id));
    } else {
      setSelectedPetIds([...selectedPetIds, petId]);
    }
  };

  return (
    <PortalPopUp
      heading="You have multiple dogs on this upcoming order"
      description="Select the dogs you would like to include moving to the new order date."
      size="xl"
      onConfirm={() => onConfirm(selectedPetIds)}
      isConfirmButtonDisabled={selectedPetIds.length === 0}
      isConfirmButtonLoading={isConfirmButtonLoading}
      useRNModal
      {...props}
    >
      <Stack w="100%" space={{ base: 4, md: 6 }}>
        <HStack
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          space={{ base: 4, md: 6 }}
        >
          {sortedActivePets.map((pet) => (
            <PetPillButton
              pet={pet}
              selected={selectedPetIds.includes(pet.id)}
              key={pet.id}
              onPress={(e) => {
                togglePet(e, pet.id);
              }}
            />
          ))}
        </HStack>

        {showDateEstimateFooter ? (
          <Stack>
            <Text alignSelf="center" color="sntGrey.secondary" size="bodySm">
              {`Charge date ${dateFormat(chargeDate, 'short')}, delivery date ${dateRangeFormat(
                deliverStartDay.toDate(),
                deliverEndDay.toDate(),
                'short'
              )}`}
            </Text>
          </Stack>
        ) : null}
      </Stack>
    </PortalPopUp>
  );
};
