import { isPup } from './dogAge';
import { capitalize } from './string';

import { OrderProduct, PetGender, PetPlanRecipeData, ProductType, RecipeType } from '@/api';
import { ASSETS_URL } from '@/constants';
import { Dog } from '@/types';

export const DEFAULT_FOOD_TYPE = RecipeType.UNKIBBLE;
export const BREAKPOINT_PLAN_SIZE = 300;

export const configStylesForContainers = {
  UNKIBBLE: {
    stackH: { base: '90%', '2xl': 'auto' },
    vStackMaxW: { base: '100%', md: '696px', '2xl': '1400px' },
  },
  FRESH: {
    stackH: { base: '90%', '2xl': 'auto' },
    vStackMaxW: { base: '100%', md: '696px', xl: '100%', '2xl': '1400px' },
  },
};

export const getRecommendationCopy = (dog: Partial<Dog>) => {
  const foodType = dog?.food_type || DEFAULT_FOOD_TYPE;
  const dogName = dog.name;
  const dogIsPup = isPup(dog.birth_year, dog.birth_month);
  const pronoun = dog?.gender === PetGender.MALE ? 'he' : 'she';
  if (foodType === DEFAULT_FOOD_TYPE) {
    if (!dogIsPup) {
      if (dog.plan_size && dog.plan_size >= BREAKPOINT_PLAN_SIZE) {
        return `We recommend any of the following four recipes for ${dogName}. You can choose one, or mix and match for more variety!`;
      }
      return `We recommend any of the following four recipes for ${dogName}. After your trial, you can add multiple recipes to ${dogName}'s plan for more variety!`;
    }
    return `UnKibble is a puppy favorite! We recommend any of the following four recipes for ${dogName} as ${pronoun} grows. After your trial, you can add multiple recipes to ${dogName}'s plan for more variety!`;
  } else {
    return dogIsPup
      ? `All of our recipes are formulated and balanced for all ages, including your growing puppy. You can choose one or mix and match for more variety!`
      : `We recommend any of the following recipes for ${dogName}. You can choose one or mix and match for more variety!`;
  }
};

export const getMealRecipeName = (recipeType: RecipeType) =>
  recipeType === RecipeType.UNKIBBLE ? 'UnKibble' : 'Fresh';

export const getReplacementOrSampleMealName = (mealProduct: OrderProduct) => {
  let type = '';
  switch (mealProduct.product_type) {
    case ProductType.SAMPLE:
      type = ' (Sample)';
      break;
    case ProductType.REPLACE:
      type = ' (Replacement)';
      break;
  }
  return `${mealProduct.quantity} x ${getMealRecipeName(mealProduct.recipes[0].type)}${type}`;
};

export const getRecipeImageSrc = (
  recipes: PetPlanRecipeData[],
  section: 'FoodType' | 'PortionSize'
) => {
  const foodType = capitalize(recipes[0].type);
  const imgSrc = `${ASSETS_URL}/pictos/PetPlans/${section}/${foodType}`;

  let recipeFilename = `${recipes[0].id}.png`;
  switch (recipes[0].type) {
    case RecipeType.UNKIBBLE:
      if (recipes.some((recipe) => recipe.id === 'U-B')) {
        recipeFilename = 'U-B.png';
      } else if (recipes.some((recipe) => recipe.id === 'U-C')) {
        recipeFilename = 'U-C.png';
      } else if (recipes.some((recipe) => recipe.id === 'U-S')) {
        recipeFilename = 'U-S.png';
      } else {
        recipeFilename = 'U-T.png';
      }
      break;
    case RecipeType.FRESH:
      if (section === 'PortionSize') {
        recipeFilename = 'FreshPortion.png';
      } else if (recipes.some((recipe) => recipe.id === 'F-B')) {
        recipeFilename = 'F-B.png';
      } else if (recipes.some((recipe) => recipe.id === 'F-L')) {
        recipeFilename = 'F-L.png';
      } else {
        recipeFilename = 'F-T.png';
      }
      break;
  }
  return `${imgSrc}/${recipeFilename}`;
};

/**
 * Given a list of recipe IDs/types, return the image URL for the product image.
 *
 * Set `inclusions` to false to get no-inclusion versions for UnKibble images
 */
export const getRecipesProductImageSrc = (
  recipes: { id: string; type: RecipeType }[],
  inclusions = true,
  image_uri?: string | null
) => {
  if (image_uri) {
    return `${ASSETS_URL}/${image_uri}`;
  }

  const recipeType = recipes[0].type;
  const noInclusionPath = !inclusions && recipeType === RecipeType.UNKIBBLE ? 'portal/' : '';
  const recipeFilename = recipes
    .map(({ id }) => id)
    .sort()
    .join('');

  return `${ASSETS_URL}/pictos/${capitalize(recipeType)}/${noInclusionPath + recipeFilename}.png`;
};

export const getRecipesBowlImageSrc = (
  recipes: string[],
  recipeType: RecipeType.FRESH | RecipeType.UNKIBBLE,
  isTopper: boolean
) => {
  return `${ASSETS_URL}/pictos/${recipeType === RecipeType.UNKIBBLE ? 'Unkibble' : 'Fresh'}/bowls/${
    isTopper ? 'topper/' : ''
  }${recipes.sort().join('')}.webp`;
};
