import { Box, IconButton, Spacer, Stack, useTheme } from 'native-base';
import React, { useState } from 'react';
import Carousel, { ICarouselInstance, TCarouselProps } from 'react-native-reanimated-carousel';

import { CarouselIndicators } from './CarouselIndicators';

import { LeftArrow, RightArrow } from '@/components/Icons';

export interface CarouselProps extends Omit<TCarouselProps, 'data'> {
  width: number;
  height?: number;
  data: any[];
  accordionInfo?: any;
  indicatorPlacement?: 'top' | 'bottom';
  variant?: 'bordered';
  onPrev?: (val?: any) => void;
  onNext?: (val?: any) => void;
  onScrollBegin?: () => void;
  direction?: 'vertical' | 'horizontal';
}

export const MyCarousel = ({
  data,
  indicatorPlacement = 'bottom',
  direction = 'horizontal',
  width,
  height,
  variant,
  renderItem,
  onPrev,
  onNext,
  onScrollEnd,
}: CarouselProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const carouselRef = React.createRef<ICarouselInstance>();
  const theme = useTheme();

  const scrollToIndex = (carouselIndex: number) => {
    setCarouselIndex(carouselIndex);
    carouselRef.current?.scrollTo({ index: carouselIndex });
  };

  return (
    <Box
      w="100%"
      h="fit-content"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <CarouselIndicators
        display={indicatorPlacement === 'top' ? 'flex' : 'none'}
        index={carouselIndex}
        length={data?.length}
        scrollTo={scrollToIndex}
      />
      <Spacer size={indicatorPlacement === 'top' ? '24px' : '0px'} />
      <Stack direction="row" justifyContent="center" alignItems="center" space="48px">
        <IconButton
          position="relative"
          disabled={carouselIndex === 0}
          icon={
            <LeftArrow
              size="xl"
              color={carouselIndex === 0 ? theme.colors.sntGrey.secondary : theme.colors.black}
            />
          }
          onPress={() => {
            if (carouselRef.current && carouselRef.current.getCurrentIndex() > 0) {
              carouselRef.current?.prev();
              if (onPrev) onPrev(carouselRef.current.getCurrentIndex());
            }
          }}
          display={{ base: 'none', md: 'flex' }}
        />
        <Carousel
          style={variant === 'bordered' ? { borderWidth: 2 } : {}}
          width={width}
          height={height}
          data={data}
          onSnapToItem={(i) => setCarouselIndex(i)}
          renderItem={renderItem}
          ref={carouselRef}
          loop={data && data.length > 1}
          onScrollBegin={onPrev}
          onScrollEnd={onScrollEnd}
          panGestureHandlerProps={
            direction === 'horizontal'
              ? {
                  activeOffsetX: [-10, 10],
                }
              : direction === 'vertical'
              ? {
                  activeOffsetY: [-10, 10],
                }
              : {}
          }
        />
        <IconButton
          position="relative"
          disabled={carouselIndex === data?.length - 1}
          icon={
            <RightArrow
              size="xl"
              color={
                carouselIndex === data?.length - 1
                  ? theme.colors.sntGrey.secondary
                  : theme.colors.black
              }
            />
          }
          display={{ base: 'none', md: 'flex' }}
          onPress={() => {
            if (carouselRef.current && carouselRef.current.getCurrentIndex() < data.length - 1) {
              carouselRef.current.next();
              if (onNext) onNext(carouselRef.current.getCurrentIndex());
            }
          }}
        />
      </Stack>
      <Spacer size={indicatorPlacement === 'bottom' ? '16px' : '0px'} />
      <CarouselIndicators
        display={indicatorPlacement === 'bottom' ? 'flex' : 'none'}
        index={carouselIndex}
        length={data?.length}
        scrollTo={scrollToIndex}
      />
    </Box>
  );
};
