import { datadogLogs } from '@datadog/browser-logs';
import { Stack, Divider, Text, Pressable, Heading, IconButton, Slider } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { MinusCircleIcon, PlusCircleIcon } from 'react-native-heroicons/solid';

import { FormSubmitButton } from '../../FormSubmitButton';
import { TopperChoiceModal } from '../../Home/ManageDogPlans';
import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';

import { BuildPlan, RecipeType, usePlanSizesForRecipes } from '@/api';
import { displayToast, LoadingSpinner, ToastType } from '@/components/Elements';
import { AddNewDogScreenNames } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useNextAddNewDogStep } from '@/hooks';
import { contactUs } from '@/utils';

const { logger } = datadogLogs;

export const AddNewDogAdjustPortionSize = ({
  navigation,
}: AddNewDogScreenProps<'AdjustPortionSize'>) => {
  const account = useAccount();
  const { petData, savePetData, savePetPlan } = useAddNewDogContext();
  const nextStep = useNextAddNewDogStep(AddNewDogScreenNames.ADJUST_PORTION_SIZE);

  const { data: planSizesData, isSuccess: isSuccessPlanSizes } = usePlanSizesForRecipes({
    recipes: petData.recipes || [],
    account_id: account.id,
  });

  const planSizes: BuildPlan[] = useMemo(() => Object.values(planSizesData ?? {}), [planSizesData]);
  const [selectedPlanSizeIndex, setSelectedPlanSizeIndex] = useState(0);
  const [showTopperChoiceModal, setShowTopperChoiceModal] = useState(false);
  const selectedPetPlan = planSizes.at(selectedPlanSizeIndex);
  const currentIsTopper = petData.plan_type === 'topper';

  const onPressChangePlanSize = (n: 1 | -1) => {
    if (selectedPlanSizeIndex + n >= 0 && selectedPlanSizeIndex + n < planSizes.length) {
      setSelectedPlanSizeIndex(selectedPlanSizeIndex + n);
    }
  };

  const onSubmit = () => {
    if (petData.plan_size === undefined || selectedPetPlan === undefined) {
      return;
    }

    const isPlanSizeDecreased = selectedPetPlan.plan_size < petData.plan_size;
    const isPlanSizeIncreased = selectedPetPlan.plan_size > petData.plan_size;
    const isFreshFood = petData.food_type === RecipeType.FRESH;

    if (
      isFreshFood &&
      ((isPlanSizeDecreased && !currentIsTopper) || (isPlanSizeIncreased && currentIsTopper))
    ) {
      setShowTopperChoiceModal(true);
    } else {
      onConfirmPortion();
    }
  };

  const onConfirmIntentToTopper = (intendsToKeepToperChoice: boolean) => {
    setShowTopperChoiceModal(false);
    onConfirmPortion(intendsToKeepToperChoice);
  };

  const onConfirmPortion = async (intentToTopper?: boolean) => {
    try {
      if (!selectedPetPlan) {
        throw new Error('selectedPetPlan is undefined');
      }
      savePetData({
        plan_size: planSizes[selectedPlanSizeIndex].plan_size,
        ...(intentToTopper !== undefined && { plan_type: intentToTopper ? 'topper' : 'full' }),
      });
      savePetPlan(selectedPetPlan);
      navigation.navigate(nextStep);
    } catch (error) {
      displayToast({
        message: `There was a problem saving your dog's portion size`,
        type: ToastType.Error,
      });
      logger.error(`Error saving dog's portion size, account_id=${account.id}`, { error });
    }
  };

  useEffect(() => {
    navigation.setOptions({ title: `Adjust ${petData?.name}'s Portion Size` });
  }, [navigation, petData]);

  useEffect(() => {
    if (petData.plan_size && isSuccessPlanSizes) {
      setSelectedPlanSizeIndex(
        planSizes.findIndex(({ plan_size }) => plan_size === petData.plan_size)
      );
    }
  }, [isSuccessPlanSizes, petData.plan_size, planSizes]);

  const ResponsiveIcon = ({ icon }: { icon: 'minus' | 'plus' }) => {
    return (
      <Stack>
        <IconButton
          p="0px"
          display={{ base: 'flex', md: 'none' }}
          onPress={() => onPressChangePlanSize(icon === 'minus' ? -1 : 1)}
          icon={
            icon === 'minus' ? (
              <MinusCircleIcon color="blueBayoux.primary" size="20px" />
            ) : (
              <PlusCircleIcon color="blueBayoux.primary" size="20px" />
            )
          }
        />
        <IconButton
          p="0px"
          display={{ base: 'none', md: 'flex' }}
          onPress={() => onPressChangePlanSize(icon === 'minus' ? -1 : 1)}
          icon={
            icon === 'minus' ? (
              <MinusCircleIcon color="blueBayoux.primary" size="30px" />
            ) : (
              <PlusCircleIcon color="blueBayoux.primary" size="30px" />
            )
          }
        />
      </Stack>
    );
  };

  return (
    <AddNewDogWrapper
      containerProps={{
        justifyContent: 'space-between',
      }}
      showHeader={false}
    >
      {selectedPetPlan ? (
        <>
          <Stack alignItems="center" space={{ base: '16px', lg: '24px' }}>
            <Heading
              fontWeight="medium"
              size="bodySmToMd"
              fontFamily="secondary"
              textAlign="center"
              maxW={{ base: '100%', md: '800px' }}
            >
              If you'd like to feed more or less food, you can adjust daily portion size below.
            </Heading>
            <Stack
              variant="card"
              alignItems="center"
              w={{ base: '100%', md: '540px' }}
              space={{ base: '16px', md: '24px' }}
            >
              <Stack>
                <Text
                  size="bodyMdToLg"
                  textAlign="center"
                  fontWeight="bold"
                  mb={{ base: '8px', md: '16px' }}
                >
                  {petData.name}&apos;s Daily Portions
                </Text>
                <Text
                  variant="helperText"
                  size="bodySmToMd"
                  color="sntGrey.tertiary"
                  fontWeight="medium"
                  textAlign="center"
                >
                  {petData.plan_type === 'topper'
                    ? 'Mixing S&T with other food'
                    : 'Feeding 100% S&T'}
                </Text>
              </Stack>
              <Divider />
              <Stack direction="row" alignItems="center" space={{ base: '16px', md: '24px' }}>
                <ResponsiveIcon icon="minus" />
                <Stack
                  direction="row"
                  space={{ base: '4px', md: '8px' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    fontSize={{ base: 'title.sm', md: 'largeTitle' }}
                    lineHeight={{ base: 'title.sm', md: 'largeTitle' }}
                    fontWeight="bold"
                  >
                    {selectedPetPlan.calories_per_day}
                  </Text>
                  <Text fontWeight="medium" fontFamily="primary" size="bodyMlToTitleSm">
                    calories
                  </Text>
                </Stack>
                <ResponsiveIcon icon="plus" />
              </Stack>
              <Slider
                value={selectedPlanSizeIndex}
                minValue={0}
                maxValue={planSizes?.length - 1}
                onChange={(value) => setSelectedPlanSizeIndex(value)}
                accessibilityLabel="calories"
              >
                <Slider.Track>
                  <Slider.FilledTrack />
                </Slider.Track>
                <Slider.Thumb />
              </Slider>
              <Text fontWeight="medium" size="bodySmToMd">
                ${selectedPetPlan.subscription.price.price_per_week}/wk
              </Text>
            </Stack>
          </Stack>
          <Stack alignItems="center" w={{ base: '100%', md: '540px' }}>
            <Text fontWeight="medium" size="bodySmToMd" color="sntGrey.primary">
              Need help determining your dog's perfect portions?{' '}
              <Pressable
                onPress={contactUs}
                textDecorationLine="underline"
                fontWeight={700}
                color="black"
                size={{ base: 'bodySm' }}
              >
                <Text size="bodySmToMd">Contact us</Text>
              </Pressable>{' '}
              for personal advice — we'll make sure their daily portions are just right!
            </Text>
            <FormSubmitButton
              onPress={onSubmit}
              isDisabled={selectedPetPlan.plan_size === petData.plan_size}
              mt="20px"
              mb="20px"
            >
              Continue
            </FormSubmitButton>
          </Stack>

          {petData.plan_size && (
            <TopperChoiceModal
              isOpen={showTopperChoiceModal}
              decreaseOrIncrease={
                selectedPetPlan.plan_size < petData.plan_size ? 'decrease' : 'increase'
              }
              petName={petData.name}
              onConfirm={onConfirmIntentToTopper}
              onClose={() => setShowTopperChoiceModal(false)}
            />
          )}
        </>
      ) : (
        <LoadingSpinner accessLabel="Add New Dog Adjust Portion Size Page" />
      )}
    </AddNewDogWrapper>
  );
};
