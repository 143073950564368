export const colors = {
  sntBlue: {
    primary: '#9FCDFF',
    primary10: '#f5faff',
    primary20: '#ecf5ff',
    primary25: '#e8f3ff',
    primary50: '#d0e6ff',
    primary75: '#b8daff',
    disabled: '#BDD1E9',
    hover: '#7FA4CC',
    info: '#E7F3FF',
  },
  blueBayoux: {
    primary: '#9FCDFF',
  },
  powderBlue: '#ECF3FF',
  black: '#000000',
  white: '#ffffff',
  baliHai: '#759aaf',
  aquaIsland: '#a5d9dc',
  sandy: '#f7946c',
  warning: '#fdead5',
  sntGrey: {
    primary: '#828282',
    athens: '#F6F7F9',
    dark: '#4a4a4a',
    outline: '#D2D2D7',
    secondary: '#c7c7c7',
    tertiary: '#9F9999', // "Rebranded Grey"
  },
  gallery: '#ECECEC',
  gallery40: 'rgba(236, 236, 236, 0.4)', // 40% opacity for #ECECEC
  error: {
    default: '#FF4E43',
    default75: '#ff7f79',
    default50: '#ffaca8',
    default25: '#ffd6d4',
    default10: '#ffeeee',
    hover: '#CC3E36',
    bg: '#FFE2E7',
    nativeBase: 'rgb(220, 38, 38)',
  },
  successGreen: '#48962D',
  transparent: 'rgba(255,255,255,0)',
};
