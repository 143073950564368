import { Badge, Divider, HStack, IStackProps, Stack, Text } from 'native-base';

import { Addons } from './Addons';
import { Meals } from './Meals';

import { Pet, PetPlan, RecipeType } from '@/api/types';
import { Avatar } from '@/components/Elements';
import { capitalize, getPetPlanAddons } from '@/utils';

export interface PlanSummaryCardProps extends IStackProps {
  pet: Pet;
  petPlan: PetPlan;
  disabled?: boolean;
}

export const PlanSummaryCard = ({ pet, petPlan, disabled, ...props }: PlanSummaryCardProps) => {
  const snacks = getPetPlanAddons(petPlan, RecipeType.SNACK, { excludeCancelled: true });
  const supplements = getPetPlanAddons(petPlan, RecipeType.SUPPLEMENT, { excludeCancelled: true });

  return (
    <Stack
      w="100%"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="sntGrey.outline"
      p={{ base: '16px', lg: '24px' }}
      space={{ base: '16px', lg: '24px' }}
      {...props}
    >
      <Stack flex={1} alignItems="start" space={{ base: '8px', lg: '16px' }}>
        <HStack w="100%" justifyContent="space-between">
          <Avatar
            petId={pet.id}
            name={`${pet.name}'s Plan`}
            nameProps={{
              size: 'bodyMlToTitleSm',
              fontWeight: 'bold',
              color: disabled ? 'sntGrey.primary' : undefined,
            }}
            petGender={pet.gender}
            imageSize={{ base: '24px', lg: '36px' }}
            containerProps={{ direction: 'row', space: '8px' }}
          />

          {disabled ? <Badge variant="smallTabInactive">{capitalize(petPlan.status)}</Badge> : null}
        </HStack>

        <Text size="bodySmToMd" color="sntGrey.primary">
          Delivered every {petPlan.frequency} weeks
        </Text>
      </Stack>

      <Divider bg="gallery" />

      <Meals
        pet={pet}
        petPlan={petPlan}
        disabled={disabled}
        successRedirectParams={{ petId: pet.id }}
      />
      {!disabled && snacks.length > 0 ? (
        <>
          <Divider bg="gallery" />
          <Addons
            addonsType={RecipeType.SNACK}
            data={snacks}
            disabled={disabled}
            pet={pet}
            petPlan={petPlan}
          />
        </>
      ) : null}
      {!disabled && supplements.length > 0 ? (
        <>
          <Divider bg="gallery" />
          <Addons
            addonsType={RecipeType.SUPPLEMENT}
            data={supplements}
            disabled={disabled}
            pet={pet}
            petPlan={petPlan}
          />
        </>
      ) : null}
    </Stack>
  );
};
