import { BuildPlan } from '@/api/types';
import { AddOnMap } from '@/context';
import { axios } from '@/lib/axios';
import { Dog } from '@/types';

export const createPetAndPetPlan = async (
  accountId: string,
  petData: Partial<Dog>,
  petPlan: BuildPlan,
  addons: AddOnMap,
  discountCode: string,
  startDate: Date
): Promise<{ pet_name: string; pet_id: string }> => {
  const {
    name,
    gender,
    is_neutered,
    birth_month,
    birth_year,
    weight,
    body_type,
    lifestyle,
    breeds,
    plan_size,
    health_conditions,
  } = petData;
  try {
    const response = await axios.post<{ pet_name: string; pet_id: string }>(
      `pet_plans/create_pet_and_pet_plan`,
      {
        pet: {
          name,
          birth_year,
          breeds,
          weight,
          gender,
          neutered: is_neutered,
          birth_month,
          recommended_calories_day: plan_size,
          body_type,
          lifestyle,
          health_conditions,
          account: accountId,
        },
        pet_plan: {
          product: petPlan?.subscription?.product?.code,
          frequency: petPlan?.subscription?.product?.frequency,
          unit_price: petPlan?.subscription?.price?.price,
          plan_size,
          quantity: 1,
          start_date: startDate,
        },
        addons,
        discount_code: discountCode,
        account_id: accountId,
      }
    );
    return response.data;
  } catch (err: unknown) {
    throw new Error(`Error creating pet and pet plan: ${err}`);
  }
};
