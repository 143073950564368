import React, { useState } from 'react';

import { ResponsiveCarousel, ResponsiveCarouselProps } from '.';
import { ReviewCard } from '..';

import { Recipe, Review } from '@/api/types/accountServices';
import { capitalize } from '@/utils';

interface ReviewData extends Review {
  recipe: Recipe;
}

export const ReviewCarousel = ({ data, width }: Partial<ResponsiveCarouselProps>) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const [reviewCarouselHeight, setReviewCarouselHeight] = useState<null | number>(null);

  const transitionCarousel = (index: number) => {
    setCarouselIndex(index);
    setReviewExpanded(false);
  };

  return (
    <ResponsiveCarousel
      data={data}
      width={width || { base: 311, md: 400 }}
      height={
        reviewCarouselHeight
          ? { base: reviewCarouselHeight, md: reviewCarouselHeight }
          : { base: 392, md: 456 }
      }
      variant="bordered"
      renderItem={({ item }: { item: ReviewData }) => {
        const cardIndex = item.seq_no - 1;
        return (
          <ReviewCard
            expanded={reviewExpanded}
            setExpanded={() => setReviewExpanded(!reviewExpanded)}
            reviewer_name={item.reviewer_name}
            picto={`${capitalize(item.recipe.type)}/${item.recipe.id}/${cardIndex}.png`}
            rating={item.rating}
            review={item.review}
            id={item.seq_no}
            setCarouselHeight={setReviewCarouselHeight}
            carouselIndex={carouselIndex}
            cardIndex={cardIndex}
          />
        );
      }}
      // Functions for desktop prev/next arrows
      onNext={transitionCarousel}
      onPrev={transitionCarousel}
      // Built in Carousel functions for swiping left/right
      onScrollBegin={() => setReviewExpanded(false)}
      onScrollEnd={(i) => setCarouselIndex(i)}
    />
  );
};
