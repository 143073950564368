import { NavigationContainerRefWithCurrent } from '@react-navigation/native';

import { SEGMENT_API_KEY } from './config';
import { RootStackParamList } from './types';

/**
 * Loads the Segment analytics module.
 * If the `SEGMENT_API_KEY` is not present in the environment, other Segment
 * functions will not do anything.
 */
export const load = () => {
  if (window.analytics && SEGMENT_API_KEY) {
    window.analytics.load(SEGMENT_API_KEY);
  }
};

const ROUTE_CATEGORIES: Record<string, string> = {
  Login: 'login',
  SetPassword: 'forgot-password',
  Home: 'home',
  Orders: 'orders',
  Dogs: 'dogs',
  Account: 'account',
};

/**
 * Calls Segment analytics.page(). "The Page method lets you record page views
 * on your website, along with optional extra information about the page viewed
 * by the user."
 */
export const useSegmentPageWithNavigation = (
  navigationRef: NavigationContainerRefWithCurrent<RootStackParamList>
) => {
  return (data: any = {}) => {
    if (!window.analytics) {
      return;
    }
    const routeName = navigationRef.getCurrentRoute()?.name;
    const identifier = (routeName && ROUTE_CATEGORIES[routeName]) || window.location.pathname;
    window.analytics.page(identifier, { ...window.location, ...data });
  };
};

export const trackEvent = async (name: string, properties: any) => {
  try {
    if (!window.analytics) {
      return;
    }
    window.analytics.track(name, { ...properties, user_id: null }); // user_id is a legacy field that was used for Shopify user id
  } catch (err) {
    console.error('Error sending Segment event:', err);
  }
};

export default { load, trackEvent };
