import { datadogLogs } from '@datadog/browser-logs';
import { Divider, Heading, IStackProps, Stack } from 'native-base';

import { displayToast, ToastType } from '../Toast';
import { LineItemPlanCardAddons } from './LineItemPlanCardAddons';
import { LineItemPlanCardExtra } from './LineItemPlanCardExtra';
import { LineItemPlanCardHeading } from './LineItemPlanCardHeading';
import { LineItemPlanCardMeal } from './LineItemPlanCardMeal';
import { LineItemPlanCardSnackOrSupplement } from './LineItemPlanCardSnackOrSupplement';

import { useUpdateOrderProducts } from '@/api';
import { OrderProduct, Pet, PetStatus } from '@/api/types/accountServices';
import { useAuth } from '@/hooks';
import { addonSeqNoCompare, extrasOrderProductCompare } from '@/utils';

const { logger } = datadogLogs;

export interface LineItemPlanCardProps extends IStackProps {
  content: {
    meals: OrderProduct[];
    snacks: OrderProduct[];
    supplements: OrderProduct[];
    extras: OrderProduct[]; // for materials, like scoops and clips
    addons: OrderProduct[];
    dog: Pet;
    orderId: string;
    orderDate: string;
    orderEditable: boolean;
    orderWasCharged: boolean;
    frequency?: number;
    planStatus?: PetStatus;
  };
  disabled?: boolean;
  onDecAddon?: (orderProduct: OrderProduct) => void;
  onIncAddon?: (orderProduct: OrderProduct) => void;
}

export const LineItemPlanCard = ({
  content,
  disabled,
  onDecAddon,
  onIncAddon,
  ...props
}: LineItemPlanCardProps) => {
  const { refetchUser } = useAuth();
  const { mutateAsync: updateOrderProducts, isLoading: isLoadingUpdateProducts } =
    useUpdateOrderProducts();

  const handleOneTimeProductStepper = async (
    productCode: string,
    productName: string,
    mode: 'increase' | 'decrease'
  ) => {
    const successVerb = mode === 'increase' ? 'added' : 'removed';
    const errorVerb = mode === 'increase' ? 'adding' : 'removing';
    const preposition = mode === 'increase' ? 'to' : 'from';
    try {
      await updateOrderProducts({
        productCode,
        mode,
      });
      await refetchUser();

      displayToast({
        message: `We've ${successVerb} ${productName} ${preposition} your next order.`,
        type: ToastType.Success,
      });
    } catch (err) {
      displayToast({
        message: `An error occurred while ${errorVerb} ${productName} ${preposition} your next order.`,
        type: ToastType.Error,
      });
      logger.error(`Error ${errorVerb} ${productName} ${preposition} order ID ${content.orderId}`);
    }
  };
  return (
    <Stack w="100%" {...props}>
      <Stack
        p={{ base: 4, lg: 6 }}
        borderWidth={1}
        borderColor="sntGrey.outline"
        w="100%"
        space={4}
      >
        <LineItemPlanCardHeading
          dog={content?.dog}
          frequency={content.frequency}
          planStatus={content.planStatus}
          orderEditable={content.orderEditable}
          disabled={disabled}
        />

        <Stack space={{ base: 4, lg: 6 }}>
          {content.meals.length > 0 && (
            <>
              <Divider bg="gallery" />
              <LineItemPlanCardMeal
                meals={content.meals}
                frequency={content.frequency}
                disabled={disabled}
              />
            </>
          )}

          {content.snacks.length ? (
            <Stack space={{ base: 4, lg: 6 }}>
              <Divider bgColor="gallery" />
              <Stack space={{ base: 4, lg: 6 }}>
                <Heading
                  size="bodyMdToLg"
                  fontWeight="bold"
                  color={disabled ? 'sntGrey.primary' : undefined}
                >
                  Snacks
                </Heading>
                {content.snacks.sort(addonSeqNoCompare).map((snack, i, arr) => (
                  <LineItemPlanCardSnackOrSupplement
                    product={snack}
                    key={snack.id}
                    disabled={disabled}
                    showDivider={i < arr.length - 1}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
          {content.supplements.length ? (
            <Stack space={{ base: 4, lg: 6 }}>
              <Divider bgColor="gallery" />
              <Stack space={{ base: 4, lg: 6 }}>
                <Heading
                  size="bodyMdToLg"
                  fontWeight="bold"
                  color={disabled ? 'sntGrey.primary' : undefined}
                >
                  Supplements
                </Heading>
                {content.supplements.sort(addonSeqNoCompare).map((supplement, i, arr) => (
                  <LineItemPlanCardSnackOrSupplement
                    product={supplement}
                    key={supplement.id}
                    disabled={disabled}
                    showDivider={i < arr.length - 1}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
          {content.extras.length > 0 ? (
            <Stack space={{ base: 4, lg: 6 }}>
              <Divider bgColor="gallery" />
              <Stack space={{ base: 4, lg: 6 }}>
                <Heading
                  size="bodyMdToLg"
                  fontWeight="bold"
                  color={disabled ? 'sntGrey.primary' : undefined}
                >
                  Extras
                </Heading>
                {content.extras.sort(extrasOrderProductCompare).map((extra, i, arr) => (
                  <LineItemPlanCardExtra
                    product={extra}
                    key={extra.id}
                    disabled={disabled}
                    showDivider={i < arr.length - 1}
                    showStepper={content.orderEditable}
                    handlePressStepper={handleOneTimeProductStepper}
                    isLoading={isLoadingUpdateProducts}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Stack>

      {content.addons?.length > 0 && (
        <>
          <Divider bg="sntGrey.outline" orientation="vertical" alignSelf="center" h={4} />
          <LineItemPlanCardAddons
            onDecAddon={onDecAddon}
            onIncAddon={onIncAddon}
            content={content}
            disabled={disabled}
          />
        </>
      )}
    </Stack>
  );
};
