import { Text, FlatList, Flex, Box, CircleIcon } from 'native-base';

import { AccordionItemContent } from '../Accordion/AccordionItem';

import { Recipe, RecipeDataPoint, RecipeType } from '@/api/types';

/**
 * Formats recipe data as PDP Accordion sections
 */
export const getAccordionInfo = (recipe: Recipe): AccordionItemContent[] => {
  let sections: { heading: string; body: React.ReactNode; visible: boolean }[] = [];

  if ([RecipeType.FRESH, RecipeType.UNKIBBLE, RecipeType.SNACK].includes(recipe.type)) {
    sections = [
      {
        visible: recipe.ingredients.length > 0,
        heading: 'Ingredients',
        body: <AccordionSimpleText text={recipe.ingredients.join(', ')} />,
      },
      {
        visible: recipe.recipe_info.length > 0,
        heading: 'Recipe Info',
        body: <AccordionRecipeInfo recipeInfo={recipe.recipe_info} />,
      },
      {
        visible: recipe.vitamins.length > 0,
        heading: `Spot & Tango's Nutrient Blend`,
        body: <AccordionSimpleText text={recipe.vitamins.join(', ')} />,
      },
      {
        visible: recipe.analysis.length > 0,
        heading: 'Nutritional Breakdown',
        body: <AccordionTwoColumnText items={recipe.analysis} />,
      },
      {
        visible: !!recipe.aafco_statement,
        heading: 'AAFCO Statement',
        body: <AccordionSimpleText text={recipe.aafco_statement} />,
      },
    ];
  } else if (recipe.type === RecipeType.SUPPLEMENT) {
    sections = [
      {
        visible: recipe.ingredients.length > 0,
        heading: 'Active Ingredients Per Chew (4g)',
        body: <AccordionTwoColumnText items={recipe.ingredients} />,
      },
      {
        visible: recipe.inactive_ingredients.length > 0,
        heading: 'Inactive Ingredients',
        body: <AccordionSimpleText text={recipe.inactive_ingredients.join(', ')} />,
      },
      {
        visible: recipe.cautions.length > 0,
        heading: 'Cautions',
        body: <AccordionSimpleText text={recipe.cautions.map(({ text }) => text).join(', ')} />,
      },
      {
        visible: recipe.for_use_in_dogs_only.length > 0,
        heading: 'For Use In Dogs Only',
        body: (
          <AccordionSimpleText
            text={recipe.for_use_in_dogs_only.map(({ text }) => text).join(', ')}
          />
        ),
      },
      {
        visible: recipe.directions_for_use.length > 0,
        heading: 'Directions For Use',
        body: <AccordionTwoColumnText items={recipe.directions_for_use.map(({ text }) => text)} />,
      },
      {
        visible: recipe.warnings.length > 0,
        heading: 'Warnings',
        body: <AccordionSimpleText text={recipe.warnings.map(({ text }) => text).join(', ')} />,
      },
    ];
  }

  return sections
    .filter(({ visible }) => visible)
    .map(({ heading, body }, index) => ({ heading, body, index }));
};

const AccordionSimpleText = ({ text }: { text: string }) => (
  <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
    {text}
  </Text>
);

const AccordionRecipeInfo = ({ recipeInfo }: { recipeInfo: RecipeDataPoint[] }) => (
  <Text size="bodySmToMd" textAlign="left">
    <FlatList
      data={recipeInfo}
      renderItem={({ item }) => (
        <Flex direction="row" justify="start" align="start">
          <Box w="fit-content" paddingTop="8px">
            <CircleIcon
              w={{ base: '4px', lg: '6px' }}
              h={{ base: '4px', lg: '6px' }}
              color="black"
            />
          </Box>
          <Box w="4px" h="4px" />
          <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
            {item.text}
          </Text>
        </Flex>
      )}
    />
  </Text>
);

const AccordionTwoColumnText = ({ items }: { items: string[] }) => (
  <>
    {items.map((item, i) => {
      const split = item.split(': ');
      return (
        <Box
          display="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          key={item + i.toString()}
        >
          <Text
            fontSize={{ base: 'body.xl', lg: 'body.sm' }}
            fontFamily="secondary"
            color="sntGrey.dark"
            fontWeight="bold"
            textAlign="left"
          >
            {split[0]}
          </Text>
          <Text fontFamily="secondary" size="bodySmToMd">
            {split[1]}
          </Text>
        </Box>
      );
    })}
  </>
);
