import axios from 'axios';
import { Flex, Stack } from 'native-base';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { defaultErrorHandler } from '../../../../utils';
import { FormSubmitButton } from '../../FormSubmitButton';
import { createPasswordResolver } from '../../resolvers';
import FieldFeedbackHelperText from './FieldFeedbackHelperText';

import { CreatePasswordData, update_password } from '@/api';
import { displayToast, ToastType } from '@/components/Elements';
import { PasswordInputFloatLabel } from '@/components/forms';
import { ProtectedScreenProps } from '@/types';
import { sendErrorReport } from '@/utils/analytics';

export const ChangePassword = ({ navigation }: ProtectedScreenProps<'ChangePassword'>) => {
  const defaultValues: CreatePasswordData = {
    old_password: '',
    password: '',
    password_confirm: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<CreatePasswordData>({
    defaultValues,
    resolver: createPasswordResolver,
    mode: 'all',
    criteriaMode: 'all',
  });
  const [focusedName, setFocusedName] = useState<string | null>(null);

  const onSubmit = async (data: CreatePasswordData) => {
    try {
      await update_password(data);
      navigation.navigate('Account');
      displayToast({
        message: 'Your password has been updated.',
        type: ToastType.Success,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          setError('old_password', { type: 'custom', message: 'Password incorrect' });
          return;
        }
      }
      sendErrorReport(error);
      defaultErrorHandler();
    }
  };

  const matchPassword = watch('password') && watch('password_confirm') === watch('password');
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      h="100%"
      w="100%"
      px={{ base: '16px', md: '0px' }}
      pt={{ base: '8px', md: '24px' }}
      pb="24px"
    >
      <Stack w={{ base: '100%', md: '540px' }}>
        <Stack
          justifyContent="center"
          alignItems="start"
          w="100%"
          h="fit-content"
          borderColor="sntGrey.outline"
          borderWidth="1px"
          borderStyle="solid"
          px={{ base: '16px', md: '24px' }}
          pt={{ base: '16px', md: '24px' }}
          space={{ base: '16px', md: '24px' }}
        >
          <PasswordInputFloatLabel
            control={control}
            name="old_password"
            placeholder="Current Password"
          />
          <Stack w="100%">
            <PasswordInputFloatLabel
              control={control}
              name="password"
              placeholder="New Password"
              inputProps={{
                onFocus: () => setFocusedName('password'),
                onBlur: () => setFocusedName(null),
              }}
              showErrors={false}
            />
            <Stack
              justifyContent="center"
              alignItems="start"
              flex={1}
              mt="16px"
              space={{ base: '4px', lg: '8px' }}
              display={focusedName === 'password' ? 'flex' : 'none'}
            >
              <FieldFeedbackHelperText
                isValid={
                  errors?.password?.types?.min !== 'min8Chars' &&
                  !(errors?.password?.types?.min as string)?.includes('min8Chars') &&
                  watch('password')
                }
                text="Include at least 8 characters"
              />
              <FieldFeedbackHelperText
                isValid={
                  errors?.password?.types?.matches !== 'min1Number' &&
                  !(errors?.password?.types?.matches as string)?.includes('min1Number') &&
                  watch('password')
                }
                text="Include at least 1 number"
              />
              <FieldFeedbackHelperText
                isValid={
                  errors?.password?.types?.matches !== 'min1SpecialChar' &&
                  !(errors?.password?.types?.matches as string)?.includes('min1SpecialChar') &&
                  watch('password')
                }
                text="Include at least 1 special character"
              />
            </Stack>
          </Stack>
          <PasswordInputFloatLabel
            control={control}
            name="password_confirm"
            placeholder="Confirm New Password"
            inputProps={{
              onFocus: () => setFocusedName('password_confirm'),
              onBlur: () => setFocusedName(null),
            }}
            showErrors={false}
          />
          <FieldFeedbackHelperText
            pb={{ base: '16px', md: '24px' }}
            isValid={matchPassword}
            text="Match password"
            display={focusedName === 'password_confirm' ? 'flex' : 'none'}
          />
        </Stack>
      </Stack>
      <Flex w={{ base: '100%', md: '381px' }} align="center">
        <FormSubmitButton
          w="100%"
          onPress={handleSubmit(onSubmit)}
          isDisabled={
            !watch('old_password') ||
            !matchPassword ||
            Object.keys(errors).length !== 0 ||
            isSubmitting
          }
        >
          Save Changes
        </FormSubmitButton>
      </Flex>
    </Stack>
  );
};
