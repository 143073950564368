import { Pressable, Flex, IFlexProps } from 'native-base';

interface IndicatorProps extends IFlexProps {
  index: number;
  length: number;
  scrollTo: (index: number) => void;
}

export const CarouselIndicators = ({ index, length, scrollTo, ...props }: IndicatorProps) => (
  <Flex direction="row" justify="center" align="center" w="fit-content" {...props}>
    {Array.from({ length }).map((_, key) => (
      <Pressable
        onPress={() => {
          if (key !== index) {
            scrollTo(key);
          }
        }}
        key={key}
        isDisabled={index === key}
        background={index === key ? 'black' : 'white'}
        borderColor="black"
        borderWidth="1px"
        w="8px"
        h="8px"
        mx="4px"
        accessibilityLabel={`Indicator for carousel image ${key}`}
      />
    ))}
  </Flex>
);
