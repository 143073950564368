import { useAccount } from './useAuth';

import { useAddonRecipes } from '@/api';
import { RecipeFull, RecipeType } from '@/api/types';

export const useRecommendedAddons = (): RecipeFull[] => {
  const account = useAccount();
  const { data: allRecipes, isSuccess } = useAddonRecipes(account?.id);
  if (!isSuccess) {
    return [];
  }

  const allAddons = allRecipes?.filter((recipe) =>
    [RecipeType.SNACK, RecipeType.SUPPLEMENT].includes(recipe.type)
  );
  let availableRecipes = allAddons || [];
  const bestSellersString = process.env.BEST_SELLER_RECIPE_IDS ?? '';
  const bestSellersList = bestSellersString.split(',');
  availableRecipes = availableRecipes.filter((recipe) => bestSellersList.includes(recipe.id));

  return availableRecipes;
};
