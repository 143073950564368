import { datadogLogs } from '@datadog/browser-logs';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Stack, Heading, Text, HStack, Link, VStack, Image } from 'native-base';
import React, { useMemo, useState } from 'react';

import { FormSubmitButton } from '../../FormSubmitButton';
import { CardBrandLogo } from '../../Home/Billing/CardBrandLogo';
import { AddNewDogScreenProps } from '../AddNewDogQuestionnaire';
import { AddNewDogWrapper } from './AddNewDogWrapper';
import {
  AddNewDogPlanSummary,
  AddNewDogPromoCard,
  AddNewDogPlanOrderSummary,
} from './PlanSummaryComponents';

import { createPetAndPetPlan, usePaymentMethods } from '@/api';
import {
  displayToast,
  EditableCard,
  HiddenTextDots,
  LoadingSpinner,
  PortalPopUp,
  ToastType,
} from '@/components/Elements';
import { LockAlertOutline } from '@/components/Icons';
import { AddNewDogScreenNames, ASSETS_URL } from '@/constants';
import { useAddNewDogContext } from '@/context';
import { useAccount, useAuth } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';
import { dateFormat, sortOrderByScheduled } from '@/utils';

const Disclaimer = ({ startDateIsToday }: { startDateIsToday: boolean }) => {
  const textProps = {
    color: 'sntGrey.primary',
    fontFamily: 'secondary',
    size: 'bodySmToMd',
    fontWeight: 700,
  };
  const linkProps = {
    href: 'https://spotandtango.com/terms-of-use',
    variant: 'inline',
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'underline',
    isExternal: true,
  };
  if (startDateIsToday) {
    return (
      <VStack space={2} justifyContent={{ base: 'left', md: 'center' }}>
        <Text {...textProps} textAlign="center">
          <LockAlertOutline size="14px" color="sntGrey.primary" mr={2} />
          All transactions are secure and encrypted through Stripe.
        </Text>
        <Text {...textProps}>
          Clicking 'Start My Dog&apos;s Plan' processes an order immediately with the above plan
          details. To modify your plan or delay the next order, please adjust the order date above
          prior to confirmation. By starting your dog&apos;s plan, you agree to Spot & Tango's{' '}
          <Link {...linkProps}>Terms of Use</Link> and understand that this is an auto-renewing
          subscription with no long-term commitment. You may pause or cancel your plan at any time.
        </Text>
      </VStack>
    );
  }
  return (
    <Text {...textProps}>
      Clicking 'Start My Dog&apos;s Plan' processes an order on the date you have selected above.
      You will not be charged until that date. By starting your dog&apos;s plan, you agree to Spot &
      Tango's <Link {...linkProps}>Terms of Use</Link>, and understand that this is an auto-renewing
      subscription with no long-term commitment. You may pause or cancel your plan at any time.
    </Text>
  );
};

const { logger } = datadogLogs;

export const AddNewDogPlanSummaryPage = ({ navigation }: AddNewDogScreenProps<'PlanSummary'>) => {
  const account = useAccount();
  const { refetchUser } = useAuth();
  const portalNavigation = useNavigation<NavigationProp<ProtectedStackParamList>>();
  const primaryAddress = account.addresses.find((addr) => addr.is_primary) || account.addresses[0];
  const { data: paymentMethods } = usePaymentMethods();
  const stripeCard = paymentMethods?.length
    ? paymentMethods.find((method) => method.is_default)?.card
    : undefined;

  const { petData, petPlan, startDate, snacks, supplements, discountCode, saveStartDate } =
    useAddNewDogContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showCombineNextOrderModal, setShowCombineNextOrderModal] = useState(false);
  const startDateIsToday = startDate.toDateString() === new Date().toDateString();
  const nextPreviewOrderDate = useMemo(() => {
    const splitDate = account.orders.upcoming
      .sort(sortOrderByScheduled)
      .at(0)
      ?.scheduled.split('-');
    if (!splitDate) return;

    const year = Number(splitDate[0]);
    const monthIndex = Number(splitDate[1]) - 1;
    const day = Number(splitDate[2]);

    return new Date(year, monthIndex, day);
  }, [account.orders.upcoming]);

  const description = (
    <Text>
      Your next order is scheduled for: <br />
      <Text fontWeight="bold">
        [
        {nextPreviewOrderDate
          ? nextPreviewOrderDate.toLocaleDateString('default', { month: 'short', day: 'numeric' })
          : 'n/a'}
        ]
      </Text>
    </Text>
  );

  const pickAnotherDateBtnText = (
    <HStack space="8px">
      <Image w="24px" h="24px" source={{ uri: `${ASSETS_URL}/pictos/Icons/Calendar.svg` }} />
      <Text fontWeight="bold">PICK ANOTHER DATE</Text>
    </HStack>
  );

  const onSubmit = async () => {
    if (!petPlan) return;
    try {
      setIsLoading(true);
      const { pet_id, pet_name } = await createPetAndPetPlan(
        account.id,
        petData,
        petPlan,
        { ...snacks, ...supplements },
        discountCode ?? '',
        startDate
      );
      segment.trackEvent('New Dog Added in Portal', {
        email: account.email,
        account_id: account.id,
        pet_name,
        pet_id,
        initial_meal_product_code: petPlan.subscription.product.code,
      });
      setIsLoading(false);
      refetchUser();
      portalNavigation.navigate('Dogs', { petId: pet_id });
      displayToast({
        message: `${petData.name}'s plan is now active.`,
        type: ToastType.Success,
      });
    } catch (error) {
      setIsLoading(false);
      displayToast({
        message: `There was a problem with creating ${petData.name}'s plan. Please contact support for assistance`,
        type: ToastType.Error,
      });
      logger.error(`Error creating pet and pet plan for ${account.id}`, { error });
    }
  };

  return (
    <AddNewDogWrapper
      containerProps={{ pb: '128px', space: 6, w: '100%', maxW: { base: '100%', lg: '850px' } }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Heading fontWeight="bold" size="titleSmToMd" mb="16px" textAlign="center">
          Get ready to join the pack, {petData.name}!
        </Heading>
        <Heading
          fontWeight="medium"
          size="bodySmToMd"
          fontFamily="secondary"
          textAlign="center"
          maxW={{ base: '100%', md: '960px' }}
        >
          Please review {petData.name}&apos;s new plan.
        </Heading>
      </Stack>
      <EditableCard
        w="100%"
        heading="Order Date"
        onPress={() => {
          if (!nextPreviewOrderDate) {
            navigation.navigate(AddNewDogScreenNames.RESCHEDULE_DELIVERY);
          } else {
            setShowCombineNextOrderModal(true);
          }
        }}
      >
        <Stack flexDirection="row" alignSelf="center">
          <Text size="bodyMdToLg" fontWeight="medium">
            {dateFormat(startDate, 'long')}
          </Text>
        </Stack>
      </EditableCard>
      <Stack w="100%" justifyContent="center" alignItems="center" space={{ base: 4, lg: 6 }}>
        {petPlan && <AddNewDogPlanSummary />}
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          space={{ base: 4, lg: 6 }}
          justifyContent="center"
          alignItems="center"
          w="100%"
        >
          <EditableCard
            h={{ lg: '100%' }}
            flex={{ lg: 1 }}
            heading="Shipping"
            onPress={() => {
              portalNavigation.navigate('AddressList', {
                successRedirect: 'AddNewDog',
              });
            }}
            stackProps={{
              direction: { base: 'row', lg: 'column' },
              space: { base: 0, lg: 4 },
            }}
          >
            <Stack
              alignItems={{ base: 'flex-end', lg: 'flex-start' }}
              space={{ base: 0, lg: 2 }}
              pr={2}
            >
              <Text size="bodyMdToLg" fontWeight="medium">
                {primaryAddress.address1}
              </Text>
              {primaryAddress.address2 ? (
                <Text size="bodyMdToLg" fontWeight="medium">
                  {primaryAddress.address2}
                </Text>
              ) : null}
              <Text size="bodyMdToLg" fontWeight="medium">
                {primaryAddress.city},{' '}
                {primaryAddress?.state ? primaryAddress.state.toUpperCase() : ''}{' '}
                {primaryAddress.zip}
              </Text>
            </Stack>
          </EditableCard>
          <EditableCard
            h={{ lg: '100%' }}
            heading="Billing"
            flex={{ lg: 1 }}
            stackProps={{
              direction: { base: 'row', lg: 'column' },
              space: { base: 0, lg: 4 },
            }}
            onPress={() =>
              portalNavigation.navigate('PaymentMethodList', {
                successRedirect: 'AddNewDog',
              })
            }
          >
            {stripeCard ? (
              <HStack alignItems="center" space={{ base: 1, lg: 2 }} pr={2}>
                <CardBrandLogo brand={stripeCard.brand} />
                <HStack display={{ base: 'none', lg: 'flex' }}>
                  <HiddenTextDots />
                </HStack>
                <Text size="bodyMdToLg" fontWeight="medium">
                  {stripeCard.last4}
                </Text>
              </HStack>
            ) : (
              <LoadingSpinner accessLabel="Add New Dog Plan Summary Page" />
            )}
          </EditableCard>
        </Stack>
        <AddNewDogPromoCard />
        <AddNewDogPlanOrderSummary />
      </Stack>
      <FormSubmitButton alignSelf="center" onPress={onSubmit} isDisabled={isLoading}>
        START MY DOG&apos;S PLAN
      </FormSubmitButton>
      <Disclaimer startDateIsToday={startDateIsToday} />
      {nextPreviewOrderDate && (
        <PortalPopUp
          isOpen={showCombineNextOrderModal}
          onConfirm={() => {
            saveStartDate(nextPreviewOrderDate);
            setShowCombineNextOrderModal(false);
          }}
          onClose={() => {
            setShowCombineNextOrderModal(false);
            navigation.navigate(AddNewDogScreenNames.RESCHEDULE_DELIVERY);
          }}
          onPressX={() => setShowCombineNextOrderModal(false)}
          heading={`Combine ${petData.name}'s order with your next shipment?`}
          description={description}
          size={{ base: 'fit-content', md: '440px' }}
          marginY="auto"
          alignSelf="center"
          headingProps={{ size: 'bodyMlToTitleSm' }}
          subHeadingProps={{ fontFamily: 'primary', size: 'bodySmToMd' }}
          confirmButtonProps={{ w: 'fit-content' }}
          confirmBtnText="YES"
          backBtnText={pickAnotherDateBtnText}
        />
      )}
    </AddNewDogWrapper>
  );
};
