import { Stack, Heading, Text, HStack, Badge } from 'native-base';

import { OrderProduct, ProductType } from '@/api/types';
import { Image } from '@/components/Elements';
import {
  getMealRecipeName,
  getRecipesProductImageSrc,
  getReplacementOrSampleMealName,
  mealPriceFormat,
} from '@/utils';

export interface LineItemPlanCardMealProps {
  meals: OrderProduct[];
  frequency: number | undefined;
  disabled?: boolean;
}

export const LineItemPlanCardMeal = ({ disabled, ...props }: LineItemPlanCardMealProps) => (
  <Stack space={1}>
    <Heading size="bodyMdToLg" fontWeight="bold" color={disabled ? 'sntGrey.primary' : undefined}>
      Meals
    </Heading>

    {props.meals.map((meal, i) => (
      <HStack key={i} py={2} space={{ base: 2, lg: 4 }}>
        <Stack w={{ base: 16, lg: 20 }} h={{ base: 16, lg: 20 }}>
          <Image
            source={{ uri: getRecipesProductImageSrc(meal.recipes, false, meal.image_uri) }}
            alt={meal.recipes[0].name}
            w="100%"
            h="100%"
            resizeMode="contain"
            opacity={disabled ? 50 : undefined}
          />
        </Stack>

        <Stack flex={3} space={2}>
          <HStack justifyContent="space-between" alignItems="center">
            <Heading
              size="bodyMdToLg"
              fontWeight="bold"
              color={disabled ? 'sntGrey.primary' : undefined}
            >
              {[ProductType.REPLACE, ProductType.SAMPLE].includes(meal.product_type)
                ? getReplacementOrSampleMealName(meal)
                : getMealRecipeName(meal.recipes[0].type)}
            </Heading>

            <Badge
              variant="smallInfoPillSecondary"
              _text={{ color: disabled ? 'sntGrey.primary' : undefined }}
              borderColor={disabled ? 'sntGrey.primary' : undefined}
            >
              {[ProductType.REPLACE, ProductType.SAMPLE].includes(meal.product_type)
                ? `$${meal.unit_price}`
                : mealPriceFormat(meal.unit_price, props.frequency)}
            </Badge>
          </HStack>
          <Text size="bodySmToMd" color="sntGrey.primary" fontWeight="medium">
            {meal.recipes.map((recipe) => recipe.name).join(', ')}
          </Text>
        </Stack>
      </HStack>
    ))}
  </Stack>
);
