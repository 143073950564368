import {
  Box,
  Button,
  Pressable,
  Heading,
  Badge,
  useBreakpointValue,
  IPressableProps,
  IButtonProps,
  HStack,
  Stack,
  Text,
} from 'native-base';
import { StarIcon } from 'react-native-heroicons/solid';

import { Pricing } from '../ProductModal/Pricing';

import { Recipe } from '@/api/types';
import { Checkbox, Image } from '@/components/Elements';
import { BannerRecipeCardBack, BannerRecipeCardFront } from '@/components/Icons/BannerRecipeCard';
import { RecipeCardInfoPillWithStar } from '@/constants';
import { getRecipesProductImageSrc } from '@/utils';

export interface RecipeCardProps extends IPressableProps {
  recipe: Recipe;
  pricing: string | number | null;
  strikethroughPrice?: string | number | null;
  discountedPrice?: string | number | null;
  pricingUnit?: string;
  selected?: boolean;
  isTrial?: boolean; // Displays/hides "Try for" copy before price
  hideBanner?: boolean;
  bannerText?: string;
  underlineButton?: {
    text: string;
    onPress: IButtonProps['onPress'];
  };
  showInclusions?: boolean;
  excludeRecipes?: string[];
}

export const RecipeCard = ({
  recipe,
  isTrial = false,
  pricing,
  strikethroughPrice,
  pricingUnit = 'meal',
  selected = false,
  underlineButton,
  discountedPrice = null,
  showInclusions = true,
  hideBanner = false,
  bannerText,
  excludeRecipes = [],
  ...props
}: RecipeCardProps) => {
  const checkboxSize = useBreakpointValue({ lg: 'lg' });
  const checkboxBaseDistance: number = useBreakpointValue({ base: 6, lg: 14 });
  const checkboxDistance = `${checkboxBaseDistance}px`;
  const checkboxDistanceTop = `${checkboxBaseDistance * 1.5}px`;
  const starIconSize = useBreakpointValue({ base: '12px', md: '16px' });
  const bannerRecipeCardSize = useBreakpointValue({
    base: { width: '150px', height: '142px' },
    md: { width: '202px', height: '204px' },
  });
  const bannerRecipeCardPosition = useBreakpointValue({
    base: {
      top: '-39px',
      left: '-44px',
    },
    md: {
      top: '-56px',
      left: '-56px',
    },
  });

  const isDisabled = (recipe.id === 'U-C' && isTrial) || excludeRecipes.includes(recipe.id);

  if (isDisabled) {
    bannerText = 'Temp Unavailable';
  }

  const pillText = recipe.info_pills
    .sort((pill1, pill2) => pill1.seq_no - pill2.seq_no)
    .slice(0, 2)
    .map((infoPill) => infoPill.text);

  // TODO: Remove temporary logic to disable 'Chicken + Brown Rice' recipe
  // https://spotandtango.atlassian.net/browse/SWE-1088
  let pressableVariant = 'productCard';
  if (isDisabled) {
    pressableVariant = 'productCardInactive';
  } else if (selected) {
    pressableVariant = 'productCardSelected';
  }
  return (
    <Box w={{ base: '100%', md: 'auto', lg: 'auto' }} my={3} mx={{ base: 0, md: 3, lg: 3 }}>
      {bannerText && !hideBanner && (
        <>
          <Box position="absolute" zIndex="99" {...bannerRecipeCardPosition} pointerEvents="none">
            <Box
              position="absolute"
              style={{ transform: [{ rotate: '-45deg' }] }}
              width="100%"
              height="100%"
              top="-6px"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                position="absolute"
                textTransform="uppercase"
                fontSize={{ base: '9px', md: '13px' }}
                lineHeight="normal"
                fontWeight="bold"
              >
                {bannerText}
              </Text>
            </Box>

            <BannerRecipeCardFront
              {...bannerRecipeCardSize}
              bgColor={isDisabled ? '#FFFFFF' : '#9FCDFF'}
            />
          </Box>
          <Box position="absolute" zIndex="-1" {...bannerRecipeCardPosition} pointerEvents="none">
            <BannerRecipeCardBack
              {...bannerRecipeCardSize}
              bgColor={isDisabled ? '#FFFFFF' : '#9FCDFF'}
            />
          </Box>
        </>
      )}
      <Pressable
        variant={pressableVariant}
        w="100%"
        alignItems="center"
        justifyContent="center"
        disabled={isDisabled}
        {...props}
      >
        <Box
          position="absolute"
          top={{
            base: 4,
            lg: checkboxDistanceTop,
          }}
          right={{
            base: 4,
            lg: checkboxDistance,
          }}
        >
          {!isDisabled && (
            <Checkbox
              isChecked={selected}
              value=""
              accessibilityLabel={`${recipe.name} select checkbox`}
              borderColor={selected ? 'black' : 'sntGrey.outline'}
              onClick={(e) => e.preventDefault()}
              variant="hoverBorder"
              size={checkboxSize}
            />
          )}
        </Box>
        <Box w="100%" alignItems={{ lg: 'center' }}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={{ base: 'row', lg: 'column' }}
            w="100%"
            justifyContent={{ base: 'flex-start', md: 'space-around', lg: 'center' }}
          >
            <Image
              source={{ uri: getRecipesProductImageSrc([recipe], showInclusions) }}
              alt={recipe.name}
              width={{ base: '100px', lg: '200px' }}
              height={{ base: '100px', lg: '200px' }}
              resizeMode="contain"
            />
            <Stack
              flexWrap="wrap"
              justifyContent="center"
              alignItems={{ base: 'flex-start', md: 'center' }}
              flex={1}
              pl={{ base: 2, md: 4 }}
              pr={{ base: 0, md: 4 }}
            >
              <Heading
                fontWeight="bold"
                size="bodyMlToTitleSm"
                textAlign={{ base: 'start', lg: 'center' }}
                width={{ base: '94%', md: '100%' }}
                pb={{ base: 2, md: 4 }}
              >
                {recipe.name}
              </Heading>
              {pillText.length > 0 && (
                <HStack
                  space={{ base: 1, lg: 2 }}
                  flexWrap={{ base: 'wrap', md: 'nowrap' }}
                  justifyContent={{ base: 'start', lg: 'center' }}
                  pb={{ base: 2, md: 4 }}
                >
                  {pillText.map((text, i) => (
                    <Badge
                      key={i}
                      size="tinyToBodyMd"
                      mb="2px"
                      {...(RecipeCardInfoPillWithStar.includes(text) && {
                        leftIcon: <StarIcon fill="#FABB05" size={starIconSize} />,
                      })}
                    >
                      {text}
                    </Badge>
                  ))}
                </HStack>
              )}
              <HStack w="100%" justifyContent={{ base: 'flex-start', md: 'center' }}>
                <Pricing
                  pricing={Number(pricing)}
                  pricingUnit={pricingUnit}
                  itemType={recipe.type}
                  isTrial={isTrial}
                  discountedPrice={
                    discountedPrice || discountedPrice === 0 ? Number(discountedPrice) : null
                  }
                  strikethroughPrice={
                    strikethroughPrice || strikethroughPrice === 0
                      ? Number(strikethroughPrice)
                      : null
                  }
                />
              </HStack>
              {underlineButton && (
                <Button
                  pt={{ base: 2, md: 6 }}
                  onPress={underlineButton.onPress}
                  variant="underlineMini"
                  size="bodySmToMd"
                >
                  {underlineButton.text}
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Pressable>
    </Box>
  );
};
