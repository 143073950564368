import { Box } from 'native-base';
import { CarouselRenderItem } from 'react-native-reanimated-carousel/lib/typescript/types';

import { MyCarousel } from '@/components/Elements';

export type ResponsiveCarouselProps = {
  data: any;
  width: { base: number; md: number };
  height: { base: number; md: number };
  variant?: 'bordered';
  onPrev?: (val?: any) => void;
  onNext?: (val?: any) => void;
  onScrollBegin?: () => void;
  onScrollEnd?: (index: number) => void;
  renderItem: CarouselRenderItem<any>;
};

export const ResponsiveCarousel = ({
  data,
  width,
  height,
  variant,
  renderItem,
  onPrev,
  onNext,
  onScrollBegin,
  onScrollEnd,
}: ResponsiveCarouselProps) => {
  return (
    <>
      {/* Small Screens */}
      <Box
        w="100%"
        display={{ base: 'flex', md: 'none' }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <MyCarousel
          onPrev={onPrev}
          onNext={onNext}
          onScrollBegin={onScrollBegin}
          onScrollEnd={onScrollEnd}
          width={width.base}
          height={height.base}
          data={data}
          renderItem={renderItem}
          variant={variant}
        />
      </Box>
      {/* Large Screens */}
      <Box
        w="100%"
        display={{ base: 'none', md: 'flex' }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <MyCarousel
          onPrev={onPrev}
          onNext={onNext}
          width={width.md}
          height={height.md}
          data={data}
          renderItem={renderItem}
          variant={variant}
        />
      </Box>
    </>
  );
};
