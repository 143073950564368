import { Heading, Box, Pressable } from 'native-base';
import { useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { ChevronDownIcon } from 'react-native-heroicons/solid';

export type AccordionItemContent = {
  heading: string;
  body: React.ReactNode | string;
  index: number;
};

type AccordionItemProps = {
  item: AccordionItemContent;
  getOpenItem?: () => number;
  updateOpenItem?: (i: number) => void;
};

export const AccordionItem = (props: AccordionItemProps) => {
  const { getOpenItem, updateOpenItem, item } = props;

  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const triggerClose = () => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 0,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  };

  const triggerOpen = () => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  };

  const handlePress = () => {
    if (getOpenItem && getOpenItem() === item.index) {
      updateOpenItem?.(-1);
    } else {
      updateOpenItem?.(item.index);
    }
  };

  return (
    <Animated.View>
      <>
        {getOpenItem && getOpenItem() === item.index ? triggerOpen() : triggerClose()}
        <Pressable
          w="100%"
          paddingTop={{ base: '16px', lg: '24px' }}
          paddingBottom={{ base: '8px', lg: '16px' }}
          onPress={handlePress}
        >
          <Box display="flex" flexDir="row" justifyContent="space-between" alignItems="center">
            <Heading size="bodySmToMd" fontWeight="bold" textAlign="left">
              {item.heading}
            </Heading>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
              <ChevronDownIcon size="19px" fill="black" />
            </Animated.View>
          </Box>
        </Pressable>

        <Animated.View style={[{ overflow: 'hidden', height: bodyHeight }]}>
          <Box
            paddingBottom={{ base: '8px', lg: '16px' }}
            onLayout={(event) => setBodySectionHeight(event.nativeEvent.layout.height)}
          >
            {item.body}
          </Box>
        </Animated.View>
      </>
    </Animated.View>
  );
};
